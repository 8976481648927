

















































@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab/mdc-tab";
@use '@material/tab/mixins' as tab-mixins;

$color_white: var(--color-text-primary);

.mdc-tab-bar {
  background-color: var(--color-surface);
  margin-bottom: 20px;
}

.mdc-tab-indicator--active {
  background-color: var(--color-highlight);

  & .mdc-tab__text-label {
    color: var(--color-text-primary);
  }
}


.mdc-tab--active {
  @include tab-mixins.text-label-color($color_white);
}

.mdc-tab {
  @include tab-mixins.text-label-color($color_white);
}

@media (min-width: 600px) and (max-width: 839px) {
  .mdc-tab-scroller {
    margin: 0;
  }
}

@media (min-width: 840px) {
  .mdc-tab-scroller {
    margin: 0 15%;
  }
}

@media (min-width: 1280px) {
  .mdc-tab-scroller {
    margin: 0 30%;
  }
}
